<template>
  <b-card class="box-shadow-0 mb-0">
    <validation-observer ref="tour-info">
      <b-card-title class="text-primary">
        Tour Info
      </b-card-title>
      <b-card-sub-title>
        Please provide the necessary informations below.
      </b-card-sub-title>
      <b-card-body class="pb-0">
        <b-row class="mb-1">
          <b-col md="6">
            <b-form-group
              label="Tour Name*"
              label-for="tour-name"
            >
              <validation-provider
                #default="{ errors }"
                name="tour name"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="tour-name"
                    v-model="tourInfo.name"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Duration*"
              label-for="duration"
            >
              <validation-provider
                #default="{ errors }"
                name="duration"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="duration"
                    v-model="tourInfo.duration"
                    placeholder="DD HH:MM:SS"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Overview*"
              label-for="overview"
            >
              <validation-provider
                #default="{ errors }"
                name="overview"
                rules="required"
              >
                <quill-editor
                  ref="overview"
                  v-model="tourInfo.overview"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Included*"
              label-for="included"
            >
              <validation-provider
                #default="{ errors }"
                name="included"
                rules="required"
              >
                <quill-editor
                  ref="included"
                  v-model="tourInfo.included"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="excluded*"
              label-for="excluded"
            >
              <validation-provider
                #default="{ errors }"
                name="Excluded"
                rules="required"
              >
                <quill-editor
                  ref="excluded"
                  v-model="tourInfo.excluded"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="additional Info*"
              label-for="additional_info"
            >
              <validation-provider
                #default="{ errors }"
                name="Additional Info"
                rules="required"
              >
                <quill-editor
                  ref="additional_info"
                  v-model="tourInfo.additional_information"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <slot></slot>
      </b-card-body>
      <div class="btn-wrapper text-right">
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="isTourInfoValid()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>{{ tourId ? 'Update' : 'Submit' }}</span>
        </b-button>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'TourInfo',
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    tourData: {
      type: Object,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      required,
      isLoading: false,
      tourInfo: {
        name: '',
        overview: '',
        duration: null,
        included: '',
        excluded: '',
        additional_information: '',
      },
    }
  },
  mounted() {
    if (this.tourId) {
      this.getTourInfo()
    }
  },
  methods: {
    getTourInfo() {
      this.tourInfo = {
        name: this.tourData.name,
        overview: this.tourData.overview,
        duration: this.tourData.duration,
        included: this.tourData.included,
        excluded: this.tourData.excluded,
        additional_information: this.tourData.additional_information,
      }
    },
    isTourInfoValid() {
      this.$refs['tour-info'].validate().then(success => {
        if (success) {
          this.callTourApi()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    callTourApi() {
      this.isLoading = true
      let url = 'tour/admin_services/tour/create/'
      let apiResponse
      if (this.tourId) {
        url = `tour/admin_services/tour/${this.tourId}/tour_update/`
        apiResponse = api.updateData(url, true, this.tourInfo)
      } else {
        apiResponse = api.postData(url, true, this.tourInfo)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `Tour info ${this.tourId ? 'updated' : 'created'} successfully`)
          this.$emit('updateTourInfo', response.data.result.id)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
