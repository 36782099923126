var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"box-shadow-0 mb-0"},[_c('validation-observer',{ref:"tour-info"},[_c('b-card-title',{staticClass:"text-primary"},[_vm._v(" Tour Info ")]),_c('b-card-sub-title',[_vm._v(" Please provide the necessary informations below. ")]),_c('b-card-body',{staticClass:"pb-0"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tour Name*","label-for":"tour-name"}},[_c('validation-provider',{attrs:{"name":"tour name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"tour-name"},model:{value:(_vm.tourInfo.name),callback:function ($$v) {_vm.$set(_vm.tourInfo, "name", $$v)},expression:"tourInfo.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Duration*","label-for":"duration"}},[_c('validation-provider',{attrs:{"name":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"duration","placeholder":"DD HH:MM:SS"},model:{value:(_vm.tourInfo.duration),callback:function ($$v) {_vm.$set(_vm.tourInfo, "duration", $$v)},expression:"tourInfo.duration"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Overview*","label-for":"overview"}},[_c('validation-provider',{attrs:{"name":"overview","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"overview",model:{value:(_vm.tourInfo.overview),callback:function ($$v) {_vm.$set(_vm.tourInfo, "overview", $$v)},expression:"tourInfo.overview"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Included*","label-for":"included"}},[_c('validation-provider',{attrs:{"name":"included","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"included",model:{value:(_vm.tourInfo.included),callback:function ($$v) {_vm.$set(_vm.tourInfo, "included", $$v)},expression:"tourInfo.included"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"excluded*","label-for":"excluded"}},[_c('validation-provider',{attrs:{"name":"Excluded","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"excluded",model:{value:(_vm.tourInfo.excluded),callback:function ($$v) {_vm.$set(_vm.tourInfo, "excluded", $$v)},expression:"tourInfo.excluded"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"additional Info*","label-for":"additional_info"}},[_c('validation-provider',{attrs:{"name":"Additional Info","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"additional_info",model:{value:(_vm.tourInfo.additional_information),callback:function ($$v) {_vm.$set(_vm.tourInfo, "additional_information", $$v)},expression:"tourInfo.additional_information"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_vm._t("default")],2),_c('div',{staticClass:"btn-wrapper text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.isTourInfoValid()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.tourId ? 'Update' : 'Submit'))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }