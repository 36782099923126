<template>
  <b-card
    class="box-shadow-0 mb-0"
  >
    <b-card-title class="text-primary">
      Tour Location
    </b-card-title>
    <b-card-sub-title>
      Please provide the necessary informations below.
    </b-card-sub-title>
    <b-card-body class="p-1">
      <b-row class="mb-1">
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Location"
                label-for="location"
              >
                <v-select
                  id="location"
                  v-model="tourLocation.location"
                  :options="districtList"
                  :reduce="district => district.id"
                  label="district"
                  class="w-100"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Latitude"
                label-for="latitude"
              >
                <b-input-group>
                  <b-form-input
                    id="latitude"
                    v-model="tourLocation.lat"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Longitude"
                label-for="longitude"
              >
                <b-input-group>
                  <b-form-input
                    id="longitude"
                    v-model="tourLocation.lng"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Around Locations"
                label-for="around_locations"
              >
                <b-form-textarea
                  id="around_locations"
                  v-model="tourLocation.around_locations"
                  rows="5"
                  no-resize
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Recommended Restaurants"
                label-for="recommended_restaurants_nearby"
              >
                <b-form-textarea
                  id="recommended_restaurants_nearby"
                  v-model="tourLocation.recommended_restaurants_nearby"
                  rows="5"
                  no-resize
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <Mapbox
            :map-latitude="mapInitialPosition[0]"
            :map-longitude="mapInitialPosition[1]"
            @getCoordinates="getCoordinatesFromMap"
            @getAddress="getAddressFromMap"
          />
        </b-col>
      </b-row>
      <div class="btn-wrapper text-right">
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="callTourApi()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>Update</span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import Mapbox from '@/components/tour/Mapbox.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'TourLocation',
  components: {
    Mapbox,
  },
  mixins: [helper, tour],
  props: {
    tourData: {
      type: Object,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isLoading: false,
      districtList: [],
      tourLocation: {
        location: '',
        lat: '',
        lng: '',
        around_locations: '',
        recommended_restaurants_nearby: '',
      },
      mapInitialPosition: [23.8001, 90.4065],
    }
  },
  mounted() {
    this.getDistrictList()
  },
  methods: {
    getTourLocation() {
      const city = this.tourData.location.name
      const location = this.districtList.filter(district => (district.district).toLowerCase() === (city).toLowerCase())[0]
      this.tourLocation = {
        location: location.id,
        lat: this.tourData.lat,
        lng: this.tourData.lng,
        around_locations: this.tourData.around_locations,
        recommended_restaurants_nearby: this.tourData.recommended_restaurants_nearby,
      }
    },
    getDistrictList() {
      api.getData('hotel_management/city_list/?country=BD', true)
        .then(response => {
          if (response.data.status) {
            this.districtList = response.data.result
            if (this.tourId) {
              this.getTourLocation()
            }
          }
        }).catch()
    },
    getCoordinatesFromMap(latitude, longitude) {
      this.tourLocation.lat = latitude
      this.tourLocation.lng = longitude
    },
    getAddressFromMap(address) {
      const districtName = this.districtList.filter(district => (district.district).toLowerCase() === (address.city).toLowerCase())[0]
      if (districtName) {
        this.tourLocation.location = districtName.id
      } else {
        this.tourLocation.location = ''
      }
    },
    callTourApi() {
      this.isLoading = true
      api.updateData(`tour/admin_services/tour/${this.tourId}/tour_update/`, true, this.tourLocation).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Tour location updated successfully')
          this.$emit('updateTourInfo', response.data.result.id)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
