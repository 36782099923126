<template>
  <b-card class="box-shadow-0 mb-0">
    <b-card-title class="text-primary">
      Other Info
    </b-card-title>
    <b-card-sub-title>
      Please provide the necessary informations below.
    </b-card-sub-title>
    <b-card-body class="pb-0">
      <b-row class="mb-1">
        <b-col md="12">
          <b-form-group
            label="Category"
            label-for="category"
          >
            <v-select
              id="category"
              v-model="OtherInfo.category"
              :options="categoryList"
              :reduce="category => category.id"
              label="name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Pickup Locations"
            label-for="pick_up"
          >
            <b-input-group>
              <b-form-input
                id="pick_up"
                v-model="OtherInfo.pick_up"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Vendor"
            label-for="vendor"
          >
            <v-select
              id="vendor"
              v-model="OtherInfo.vendor"
              :options="vendorList"
              :reduce="vendor => vendor.id"
              label="name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tour Timing"
            label-for="tour_timing"
          >
            <b-input-group>
              <b-form-input
                id="tour_timing"
                v-model="OtherInfo.tour_timing"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Start Timing"
            label-for="starting_time"
          >
            <b-input-group>
              <b-form-timepicker
                id="intent-time"
                v-model="OtherInfo.starting_time"
                locale="en"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <quill-editor
              ref="description"
              v-model="OtherInfo.description"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Requirements"
            label-for="requirements"
          >
            <quill-editor
              ref="requirements"
              v-model="OtherInfo.requirements"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Travel Tips"
            label-for="travel_tips"
          >
            <quill-editor
              ref="travel_tips"
              v-model="OtherInfo.travel_tips"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Option Type"
            label-for="option_type"
          >
            <v-select
              id="option_type"
              v-model="OtherInfo.option_type"
              :options="otherType"
              :reduce="options => options.value"
              label="name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Priority"
            label-for="priority"
          >
            <b-input-group>
              <b-form-input
                id="priority"
                v-model="OtherInfo.priority"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h6 class="text-bold mb-1 text-primary">
            Pax Required
          </h6>
          <b-form-group>
            <b-form-checkbox
              v-model="OtherInfo.is_pax_required"
              name="pax_required"
              switch
              class="switch-box"
            >
              <span class="switch-icon-left">
                YES
              </span>
              <span class="switch-icon-right">
                NO
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h6 class="text-bold mb-1 text-primary">
            Is Bookable ?
          </h6>
          <b-form-group>
            <b-form-checkbox
              v-model="OtherInfo.is_bookable"
              name="is_bookable"
              switch
              class="switch-box"
            >
              <span class="switch-icon-left">
                YES
              </span>
              <span class="switch-icon-right">
                NO
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="btn-wrapper text-right">
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="callTourApi()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>Update</span>
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'OtherInfo',
  components: {
    quillEditor,
  },
  mixins: [helper],
  props: {
    tourData: {
      type: Object,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isLoading: false,
      categoryList: [],
      vendorList: [],
      OtherInfo: {
        category: '',
        tour_timing: '',
        starting_time: '',
        priority: null,
        address: '',
        description: '',
        pick_up: '',
        requirements: '',
        travel_tips: '',
        option_type: '',
        vendor: '',
        is_pax_required: null,
        is_bookable: null,
      },
      otherType: [
        { value: 0, name: 'Single' },
        { value: 1, name: 'Multiple' },
      ],
    }
  },
  mounted() {
    this.getCategoryList()
    this.getVendorList()
    if (this.tourId) {
      this.getOtherInfo()
    }
  },
  methods: {
    getCategoryList() {
      api.getData('tour/admin_services/category_list/', true).then(response => {
        if (response.data.status) {
          this.categoryList = response.data.result.results
        }
      }).catch()
    },
    getVendorList() {
      api.getData('tour/admin_services/vendor_list/?offset=0', true).then(response => {
        if (response.data.status) {
          this.vendorList = response.data.result.results
        }
      }).catch()
    },
    getOtherInfo() {
      this.OtherInfo = {
        category: this.tourData.category,
        tour_timing: this.tourData.tour_timing,
        starting_time: this.tourData.starting_time,
        priority: this.tourData.priority,
        address: '',
        description: this.tourData.description,
        pick_up: this.tourData.pick_up,
        requirements: this.tourData.requirements,
        travel_tips: this.tourData.travel_tips,
        option_type: this.tourData.option_type,
        vendor: this.tourData.vendor,
        is_pax_required: this.tourData.is_pax_required,
        is_bookable: this.tourData.is_bookable,
      }
    },
    callTourApi() {
      this.isLoading = true
      this.OtherInfo.category = this.OtherInfo.category.id
      this.OtherInfo.vendor = this.OtherInfo.vendor.id
      const formData = this.OtherInfo
      api.updateData(`tour/admin_services/tour/${this.tourId}/tour_update/`, true, formData).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Tour extra informations updated successfully')
          this.$emit('updateTourInfo', response.data.result.id)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
.switch-box {
  .custom-control-label {
    &::before {
      width: 60px;
    }
    .switch-icon-right {
      right: 5px;
    }
    .switch-icon-left {
      left: 10px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(2.5rem);
  }
}
</style>
